"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.iapSubscriptionEnvironmentSchema = exports.iapSubscriptionSchema = exports.iapSubscriptionTypeSchema = exports.IapSubscriptionType = void 0;
const zod_1 = require("zod");
const services_1 = require("../../services");
const platform_1 = require("../platform");
const iap_subscription_transaction_1 = require("./iap-subscription-transaction");
const user_schema_1 = require("../user/user-schema");
var IapSubscriptionType;
(function (IapSubscriptionType) {
    IapSubscriptionType["INVISIBILITY"] = "INVISIBILITY";
})(IapSubscriptionType = exports.IapSubscriptionType || (exports.IapSubscriptionType = {}));
exports.iapSubscriptionTypeSchema = zod_1.z.nativeEnum(IapSubscriptionType);
exports.iapSubscriptionSchema = zod_1.z.object({
    ...services_1.standardAttributes,
    userId: zod_1.z.string().uuid(),
    type: exports.iapSubscriptionTypeSchema,
    platform: platform_1.platformSchema,
    platformId: zod_1.z.string(),
    receipt: zod_1.z.string(),
    validTill: zod_1.z.date(),
});
(0, services_1.checkSchema)(exports.iapSubscriptionSchema);
exports.iapSubscriptionEnvironmentSchema = zod_1.z.object({
    id: zod_1.z.string().uuid(),
    subscription: exports.iapSubscriptionSchema,
    user: user_schema_1.userSchema,
    transactions: zod_1.z.array(iap_subscription_transaction_1.iapSubscriptionTransactionSchema),
});
