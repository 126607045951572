import React, { ReactNode, useCallback } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useRouteMatch } from "react-router-dom";
import { User, IapSubscriptionTransaction } from "shared";
import {
  Breadcrumbs,
  Spinner,
  TBody,
  TR,
  TH,
  TD,
  Table,
  Button,
} from "client-lib";
import {
  cutId,
  HeaderCell,
  HeaderRow,
  List,
  ListCell,
  ListRow,
  ParseError,
} from "src/lib";
import { Layout } from "src/features/layout";
import {
  AdminIapSubscriptionEnvironmentQuery,
  AdminIapSubscriptionEnvironmentData,
  AdminIapSubscriptionEnvironmentVars,
  AdminRefreshIap,
  AdminRefreshIapData,
  AdminRefreshIapVars,
} from "./queries";

const columnTemplate = "1fr 1fr 1.2fr 1.2fr 1.5fr";

function Wrapper({
  children,
  user,
  userId,
  iapSubscriptionId,
}: {
  children?: ReactNode;
  user?: User;
  userId: string;
  iapSubscriptionId: string;
}) {
  return (
    <Layout>
      <Breadcrumbs
        items={[
          { title: "Users", url: "/users" },
          {
            title: user ? `${user.nameFirst} ${user.nameLast}` : `${userId}`,
            url: `/users/${userId}`,
          },
          { title: `Subscription #${iapSubscriptionId}` },
        ]}
      />
      <h1>
        In-App Subscription #{iapSubscriptionId} of user{" "}
        {user ? `\u00ab${user.nameFirst} ${user.nameLast}\u00bb` : `${userId}`}
      </h1>
      {children}
    </Layout>
  );
}

export function IapSubscriptionPage() {
  const { params } = useRouteMatch<{
    userId: string;
    iapSubscriptionId: string;
  }>();
  const { data, loading, error } = useQuery<
    AdminIapSubscriptionEnvironmentData,
    AdminIapSubscriptionEnvironmentVars
  >(AdminIapSubscriptionEnvironmentQuery, {
    variables: {
      iapSubscriptionId: params.iapSubscriptionId,
    },
  });

  const [refrsehIap] = useMutation<AdminRefreshIapData, AdminRefreshIapVars>(
    AdminRefreshIap,
    {
      variables: {
        iapSubscriptionId: params.iapSubscriptionId,
      },
    }
  );

  const handleRefresh = useCallback(async () => {
    try {
      await refrsehIap();
      alert("Done");
    } catch (err) {
      alert("An error!");
      console.log(err);
    }
  }, [refrsehIap]);

  if (loading) {
    return (
      <Wrapper
        userId={params.userId}
        iapSubscriptionId={params.iapSubscriptionId}
      >
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper
        userId={params.userId}
        iapSubscriptionId={params.iapSubscriptionId}
      >
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return (
      <Wrapper
        userId={params.userId}
        iapSubscriptionId={params.iapSubscriptionId}
      />
    );
  }

  const { subscription, user, transactions } =
    data.AdminIapSubscriptionEnvironment;

  return (
    <Wrapper
      userId={params.userId}
      iapSubscriptionId={params.iapSubscriptionId}
      user={user}
    >
      <Table autoWidth>
        <TBody>
          <TR>
            <TH>ID</TH>
            <TD>{subscription.id}</TD>
          </TR>
          <TR>
            <TH>Type</TH>
            <TD>{subscription.type}</TD>
          </TR>
          <TR>
            <TH>Platform</TH>
            <TD>{subscription.platform}</TD>
          </TR>
          <TR>
            <TH>Platform&nbsp;ID</TH>
            <TD>{subscription.platformId}</TD>
          </TR>

          <TR>
            <TH>Receipt</TH>
            <TD>{subscription.receipt}</TD>
          </TR>
          <TR>
            <TH>Valid till</TH>
            <TD>{new Date(subscription.validTill).toLocaleString()}</TD>
          </TR>
        </TBody>
      </Table>
      <br />
      <Button onClick={handleRefresh}>Refresh</Button>

      <h2>Transactions</h2>
      <List>
        <HeaderRow columnTemplate={columnTemplate}>
          <HeaderCell>Created at</HeaderCell>
          <HeaderCell>ID</HeaderCell>
          <HeaderCell>Platform ID</HeaderCell>
          <HeaderCell>Platform data</HeaderCell>
          <HeaderCell>Valid till</HeaderCell>
        </HeaderRow>
        {transactions.map((t: IapSubscriptionTransaction) => {
          return (
            <ListRow key={t.id} columnTemplate={columnTemplate}>
              <ListCell>{new Date(t.createdAt).toLocaleString()}</ListCell>
              <ListCell>{cutId(t.id)}</ListCell>
              <ListCell>{t.platformId}</ListCell>
              <ListCell>
                <Button
                  size="S"
                  onClick={() => {
                    alert(t.platformData);
                    console.log(t.platformData);
                  }}
                >
                  Get data
                </Button>
              </ListCell>
              <ListCell>{new Date(t.validTill).toLocaleString()}</ListCell>
            </ListRow>
          );
        })}
      </List>
    </Wrapper>
  );
}
