import React, { ReactNode, useCallback } from "react";
import { useQuery } from "@apollo/client";
import { useRouteMatch } from "react-router-dom";
import { User, AuthChannel } from "shared";
import {
  Breadcrumbs,
  Spinner,
  TBody,
  TR,
  TH,
  TD,
  Table,
  useBoolean,
  Button,
} from "client-lib";
import { ParseError } from "src/lib";
import { Layout } from "src/features/layout";
import {
  AdminUserEnvironmentQuery,
  AdminUserEnvironmentData,
  AdminUserEnvironmentVars,
} from "./queries";
import { EmailModal } from "./email-form";

function Wrapper({
  children,
  user,
  userId,
  authChannelId,
}: {
  children?: ReactNode;
  user?: User;
  userId: string;
  authChannelId: string;
}) {
  return (
    <Layout>
      <Breadcrumbs
        items={[
          { title: "Users", url: "/users" },
          {
            title: user ? `${user.nameFirst} ${user.nameLast}` : `${userId}`,
            url: `/users/${userId}`,
          },
          { title: `AuthChannel #${authChannelId}` },
        ]}
      />
      <h1>
        AuthChannel #{authChannelId} of user{" "}
        {user ? `\u00ab${user.nameFirst} ${user.nameLast}\u00bb` : `${userId}`}
      </h1>
      {children}
    </Layout>
  );
}

export function AuthChannelPage() {
  const { params } = useRouteMatch<{ userId: string; authChannelId: string }>();
  const { data, loading, error } = useQuery<
    AdminUserEnvironmentData,
    AdminUserEnvironmentVars
  >(AdminUserEnvironmentQuery, {
    variables: {
      userId: params.userId,
    },
  });

  const authChannel: AuthChannel | undefined = data
    ? data.AdminUserEnvironment.authChannels.find(
        (c) => c.id === params.authChannelId
      )
    : undefined;

  const [isSendEmailOpen, { set: openSendEmail, reset: closeSendEmail }] =
    useBoolean(false);

  const handleSent = useCallback((result: string | null) => {
    if (result) {
      alert(result);
    } else {
      alert("Queued!");
    }
  }, []);

  if (loading) {
    return (
      <Wrapper userId={params.userId} authChannelId={params.authChannelId}>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper userId={params.userId} authChannelId={params.authChannelId}>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return (
      <Wrapper userId={params.userId} authChannelId={params.authChannelId} />
    );
  }

  if (!authChannel) {
    return (
      <Wrapper userId={params.userId} authChannelId={params.authChannelId}>
        <p>Auth channel not found</p>
      </Wrapper>
    );
  }

  const {
    AdminUserEnvironment: { user },
  } = data;
  return (
    <Wrapper
      userId={params.userId}
      authChannelId={params.authChannelId}
      user={user}
    >
      <Table autoWidth>
        <TBody>
          <TR>
            <TH>ID</TH>
            <TD>{authChannel.id}</TD>
          </TR>
          <TR>
            <TH>Type</TH>
            <TD>{authChannel.type}</TD>
          </TR>
          <TR>
            <TH>Key</TH>
            <TD>{authChannel.channelKey}</TD>
          </TR>
          <TR>
            <TH>Verified at</TH>
            <TD>
              {authChannel.verifiedAt
                ? new Date(authChannel.verifiedAt).toLocaleString()
                : "Not verified"}
            </TD>
          </TR>
          <TR>
            <TH>Is suspended</TH>
            <TD>{authChannel.isSuspended ? "Yes" : "No"}</TD>
          </TR>
        </TBody>
      </Table>
      {isSendEmailOpen && (
        <EmailModal
          authChannelId={params.authChannelId}
          onCloseRequest={closeSendEmail}
          onSent={handleSent}
        />
      )}
      <br />
      <Button onClick={openSendEmail}>Send email</Button>
    </Wrapper>
  );
}
